.input-checkbox-groups {
    display: flex;
    flex-wrap: wrap;
}

.input-checkbox-group {
    display: flex;
    align-items: center;
}

.input-checkbox, .input-checkbox-label {
    cursor: pointer;
}