.language-icon {
    display: flex;
    align-items: center;
}

.language-icon-caption {
    margin-left: 5px;
    color: #7F2DB1;
}

.language-icon svg path,
.language-icon svg circle {
    fill: #7F2DB1;
}

.language-content {
    padding: .5rem 0;
    max-height: 5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.language-content-item {
    display: flex;
    flex: 1;
    padding: .5rem 0;
    width: 7rem;
    justify-content: center;
    margin-right: 1rem;
    cursor: pointer;
    transition: all linear .2s;
}

.language-content-item:hover {
    transition: all linear .2s;
    background-color: #e7e7e7;
}

.language-content-item-active {
    background-color: #e7e7e7;
}