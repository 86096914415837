:root {
  --black: #000000;
  --white: #ffffff;
  --palewhite: #f9f9f9;
  --lightgray: #ebebeb;
  --gray: #828282;
  --darkgray: #323232;
  --purple: #7f2db1;
  --red: red;
  --blue: rgb(165, 199, 238);
  --lightblue: rgba(165, 199, 238, 0.6);
  --lighterblue: rgba(165, 199, 238, 0.25);
  --brown: #3e3a41;
  --grayborder: #bdbdbd;
  --headergray: #333333;
}
body {
  font-family: 'Roboto', sans-serif;
}

input {
  height: 24px;
}

button {
  height: 1.5rem;
}

select,
option {
  height: 2rem;
}

.clickable {
  cursor: pointer;
}
svg path {
  fill: #ffffff;
}
circle {
  fill: #ffffff;
}
