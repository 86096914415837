.app-content {
    padding: 0;
}

.register-page-title {
    color: #323232;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
}

.register-page-submit {
    width: 100%;
    height: 50px;
    color: white;
    background: linear-gradient(302.43deg, #A788FF 51.06%, #D77EF7 99.23%);;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: all 0.5s;
    margin-bottom: 30px;
}

.register-page-submit .loader {
    width: 45px;
    height: 45px;
}

.register-page-submit .loader div {
    width: 30px;
    height: 30px;
    border: 5px solid white;
    border-color: white transparent transparent transparent;
}

.register-page-submit-loading,
.register-page-submit:hover {
    filter: brightness(.8);
    transition: all 0.5s;
}

.register-page-form .input-field {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.register-page-form .input-label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #828282;
    font-size: 12px;
    margin-bottom: 5px;
}

.register-page-form .input-text {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    padding-left: 10px;
    padding-right: 10px;
}
.register-page-form .input-text:focus {
    outline: 1px solid #7F2DB1;
}

.register-page-notes {
    margin-bottom: 15px;
}

.register-page-notes-text {
    color: #828282;
    font-size: 12px;
}

.register-page-link {
    color: #7F2DB1;
    cursor: pointer;
    font-size: 12px;
}

.register-page-link:hover {
    text-decoration: underline;
}

.register-page-error {
    color: #EB5757;
    margin-bottom: 15px;
    font-size: 12px;
}
