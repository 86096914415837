.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.modal-container {
    background-color: var(--white);
    min-height: 100px;
    min-width: 450px;
    z-index: 1000;
    border-radius: .5rem;
}