.app-container,
.app-background-title,
.app-content {
    display: flex;
}

.app-container {
    flex-direction: column;
}

.app-background {
    height: 40vh;
    background-color: #f8f8f8;
}

.app-background-title {
    align-items: center;
    padding: 0 45px;
}

.app-title {
    margin-left: 15px;
    margin-bottom: 20px;
}

.app-content-container {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 999;
}

.app-content-content {
    box-sizing: border-box;
    width: 80%;
    max-width: 425px;
    min-height: 450px;
    padding: 45px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 13px rgba(137, 107, 223, 0.1);
    background-color: white;
}

.app-content-language {
    box-sizing: border-box;
    width: 80%;
    max-width: 425px;
    margin-top: 15px;
}

.app-content-language .dropdown-toggle {
    width: 20%;
}