.dropdown {
    position: relative;
}

.dropdown-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-menu {
    position: absolute;
    background: var(--palewhite);
    border: 1px solid var(--lightgray);
    border-radius: .5rem;
    min-width: 2rem;
    min-height: 2rem;
    box-shadow: 0px 2px 13px 0px rgba(137, 107, 223, 0.1);
    z-index: 1;
}