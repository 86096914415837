.input-field {
  display: flex;
  align-items: center;
}

.input-text {
  border: 1px solid var(--grayborder);
  border-radius: 4px;
}

.input-text-icon:focus-within {
  border: 1px solid black;
}

.input-text-icon {
  display: flex;
  align-items: center;
  background-color: white;
}

.input-text-icon img {
  height: 20px;
}

.input-text-inner {
  flex: 1;
  height: 100%;
  background-color: transparent;
  border: transparent;
}

.input-text-inner:focus {
  outline: none;
}